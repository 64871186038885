import Facebook from '../../../assets/svgs/facebook.svg';
import Instagram from '../../../assets/svgs/instagram.svg';
import Linkedin from '../../../assets/svgs/linkedin.svg';
import Twitter from '../../../assets/svgs/twitter.svg';
import Youtube from '../../../assets/svgs/youtube.svg';

export const getLinks = (search = '') => [
  [
    { path: '/como-calcular-frete', text: 'Como Funciona' },
    { path: '/privacidade', text: 'Política de privacidade' },
    { path: '/termos', text: 'Termos e condições' },
    {
      path: '/central-do-frete-cnpj-22531311000110',
      text: 'Dados cadastrais e CNPJ',
    },
    {
      path: '/manual-da-marca',
      text: 'Manual da Marca',
    },
  ],
  [
    {
      path: `https://app.centraldofrete.com/`,
      text: 'Painel do Embarcador',
    },
    {
      path: `https://app.centraldofrete.com/esqueceu-sua-senha/${search}`,
      text: 'Esqueci minha senha',
    },
    { path: '/cadastro', text: 'Cadastre-se' },
    { path: '/rastreio-de-encomendas', text: 'Rastreio de encomendas' },
  ],

  [
    { path: '/sobre', text: 'Sobre' },
    { path: '/vagas', text: 'Vagas' },
    { path: 'https://blog.centraldofrete.com/', text: 'Blog' },
    { path: '/falecomagente', text: 'Fale Conosco' },
  ],
];

export const socialLinks = [
  { Icon: Facebook, path: 'https://www.facebook.com/centraldofretecom/' },
  { Icon: Twitter, path: 'https://twitter.com/centralfretecom' },
  {
    Icon: Linkedin,
    path: 'https://www.linkedin.com/company/centraldofrete-com/',
  },
  {
    Icon: Youtube,
    path: 'https://www.youtube.com/channel/UCia5BwWsIJDFl-gGlKwSWZA',
  },
  { Icon: Instagram, path: 'https://www.instagram.com/centraldofretecom/' },
];
